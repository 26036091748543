import Header from "../shared/Header";
import Footer from "../shared/Footer";

function PageAbout() {
    document.title = 'Om - migrernu';

    return (
            <div className="row">
                <Header/>
                        <div className="col-md-12 align-self-center">
                            <h5>Vi er eksperter i at hjælpe virksomheder med at migrere deres eksisterende infrastruktur fra traditionelle fysiske servere (bare metal) til Docker og fra Docker til Kubernetes.</h5>
                            <br/>
                            <h5>Vores mål er at gøre denne overgang problemfri, og så få din virksomhed til at nyde alle fordelene ved Kubernetes.</h5>
                        </div>
                <Footer/>
            </div>
    );
}

export default PageAbout;
