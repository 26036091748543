import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageWelcome from './page/PageWelcome';
import Page404 from "./page/Page404";
import PageAbout from "./page/PageAbout";
import PageServices from "./page/PageServices";

export interface IApplicationProps {}

const App: React.FunctionComponent<IApplicationProps> = (props) => {
    return (
        <div className="container-md">
            <BrowserRouter>
                <Routes>
                    <Route path="/">
                        <Route index element={<PageWelcome />} />
                         <Route path="/tjenester" element={<PageServices />} />
                         <Route path="/om" element={<PageAbout />} />
                        <Route path="*" element={<Page404 />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
