import Header from '../shared/Header';
import Footer from "../shared/Footer";

function Page404() {
    return (
            <div className="row">
                <Header />
                <div className="col-md-12">
                    <br />
                    <br />
                    <br />
                    <h1 className="text-lg-center">
                        Kan ikke findes
                    </h1>
                    <br />
                    <img src="404.png" width="100%" alt="error" />
                    <br />
                    <br />
                    <br />
                </div>
                <Footer/>
            </div>
    );
}

export default Page404;
