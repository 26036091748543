import Header from "../shared/Header";
import Footer from "../shared/Footer";

function PageServices() {
    document.title = 'Tjenester - migrernu';

    return (
            <div className="row">
                <Header/>
                        <div className="col-12  align-self-center">
                            <ul>
                                <li>
                                    <h4>Migrationsplanlægning og rådgivning:</h4> Vi foretager en grundig vurdering af din eksisterende infrastruktur og forretningsbehov for at udvikle en omfattende migrationsplan. Vi rådgiver dig om de bedste praksis og tilpasser løsningen til din virksomhed.
                                </li>
                               <br/>
                                <li>
                                    <h4>Bare metal til Docker migrering:</h4> Hvis du har en infrastruktur baseret på fysiske servere, hjælper vi med at transformere den til en skalerbar Docker infrastruktur. Vi tager os af hardwareintegration, netværksopsætning og sikkerhedsaspekter for at sikre en vellykket migrering.
                                </li>
                                <br/>
                                <li>
                                    <h4>Docker til Kubernetes migrering:</h4> Vi hjælper med at migrere dine Docker-containere til Kubernetes klare arbejdsbelastninger. Vi sikrer, at din applikation fungerer problemfrit i Kubernetes miljøet og udnytter Kubernetes funktioner som autoscalering og belastningsafbalancering.
                                </li>
                                <br/>
                                <li>
                                    <h4>Overvågning og support:</h4>Vi tilbyder løbende overvågning og support af dit Kubernetes eller Docker miljø for at sikre stabil drift og hurtig fejlhåndtering. Vores team er tilgængeligt for at hjælpe dig med eventuelle spørgsmål eller problemer.
                                </li>
                                 <br/>
                                <li>
                                    <h4>Implementering og konfiguration:</h4> Vi tager os af implementeringen af Kubernetes klustre både på bare-metal og på skyen (GCP, AWS, Azure, Digital ocean, Oracle cloud), opsætningen af netværk og sikkerhedskonfiguration. Vi sikrer, at dit miljø er optimeret til at opnå høj ydeevne, skalerbarhed og pålidelighed.
                                    <ul>
                                        <li>
                                            SSL certificate håndtering
                                        </li>
                                        <li>
                                            Ingress (HTTP, HTTPS, GRPC)
                                        </li>
                                        <li>
                                            Network policies (Firewall)
                                        </li>
                                        <li>
                                            Role based Access management (RBAC)
                                        </li>
                                        <li>
                                            Vertikal og horizontal auto scalering
                                        </li>
                                        <li>
                                            100% tilgængeligehed
                                        </li>
                                        <li>
                                            Ingress (HaProxy, Nginx, Contour)
                                        </li>
                                        <li>
                                           Secret behandling med en Key Management System (Hashicorp vault, Azure vault, osv ...)
                                        </li>
                                        <li>
                                            NFS storage
                                        </li>
                                        <li>
                                            Block volume storage
                                        </li>
                                        <li>
                                            Cluster backup/restore
                                        </li>
                                        <li>
                                            Cluster migrering
                                        </li>
                                        <li>
                                            CI/Continuous integration (Jenkins)
                                        </li>
                                        <li>
                                            CD/Continuous delivery (ArgoCD, Watchtower, Docker-compose )
                                        </li>
                                        <li>
                                            Observalibiltet/metrikker (Prometheus)
                                        </li>
                                        <li>
                                           Advanceret logning (Fluentd, Logstash, Elastic-search, Kibana)
                                        </li>
                                        <li>
                                            Notifikationer (Alert manager, Uptime-kuma)
                                        </li>
                                        <li>
                                          Terraform
                                        </li>
                                        <li>
                                            Ansible
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                <Footer/>
            </div>
    );
}

export default PageServices;
