import React from 'react';

function Footer() {
    return (
        <footer className='footer mt-auto py-3'>
            <br />
            <br />
            <div className='container pb-1 pb-lg-5 text-lg-center'>
                <p className='small'>©migrernu.dk</p>
                <p className='small'>Email : kontakt@migrernu.dk</p>
            </div>
        </footer>
    );
}

export default Footer;
