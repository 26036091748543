import Header from "../shared/Header";
import Footer from "../shared/Footer";

function PageWelcome() {
    document.title = 'Velkommen - migrernu';

    return (
            <div className="row">
                <Header/>
                        <div className="col-8 align-self-center">
                            <h1>DIN VEJ TIL DOCKER OG KUBERNETES</h1>
                            <br />
                            <h5>Velkommen til migrernu.dk , din pålidelige partner inden for Kubernetes og Docker migrering!</h5>
                        </div>
                        <div className="col-4 align-self-center">
                            <img alt="Kubernetes" width="100%" src="img/kubernetes.png"></img>
                        </div>
                <Footer/>
            </div>
    );
}

export default PageWelcome;
